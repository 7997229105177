.TOS {
    margin: 0 auto;
    max-width: 1200px;
    padding: 2rem 8rem;
    color: #dedede;
}



.TOS p{
    font-family: Vivala, sans-serif;
    color: #dedede;
}

.TOS h2, .TOS h3{
    font-family: BrunoAce, sans-serif;
    font-weight: bold;
    text-align: center;
}

.TOS p a{
    text-decoration: none;
    color: #037cd3;
}

.TOS p a:hover{
    text-decoration: underline;
    color: #3ba3f1;
}