.Plans {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90vw;
    max-width: 1400px;
}

.Reason,
.Descriptions {
    position: relative;
    overflow: hidden;
    margin: 3rem 1.5rem;
    font-family: Vivala, sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 1rem;
    width: 22%;
    border-radius: 0.7rem;
    box-sizing: border-box;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.50);
    text-decoration: none;
    color: white;
    z-index: 5;
}


.PackageHeading {
    width: 100%;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    display: flex;
    flex-direction: column;
    background: #1b1e21;
    align-items: center;
    justify-content: center;
}

.PackageHeading p {
    color: ghostwhite;
    margin: 0;
    font-family: Bungee, sans-serif;
}

.Descriptions h2 {
    margin: 0;
    font-size: 1.5rem;
    font-family: Falcon, sans-serif;
}

/*.Ark h2,*/
/*.GarysMod h2{*/
/*    font-size: 1.5rem;*/
/*}*/


.Descriptions li {
    font-weight: bold;
    display: flex;
    list-style: none;
    margin: 0.5rem 0;
    text-align: left;
    align-items: center;
    color: #9a9a9a;
}
.Descriptions li p {
    padding: 0;
    margin: 0 0 0 0.5rem;
}


.Features {
    margin: 0.1rem 1rem 1rem;
}

.ViewButton {
    text-decoration: none;
    display: flex;
    margin: 0 auto;
    font-family: Falcon, sans-serif;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
    border: 0 solid transparent;
    height: 2.5rem;
    width: 10rem;
    background: #11AB59;
    color: white;
    justify-items: center;
    justify-content: center;
    align-items: center;
}

.SelectedButton {
    background: #098adb !important;
}

.ViewButton:hover,
.OptionsEnding a:hover {
    border: 0.25rem solid #098adb !important;
    background: linear-gradient(#1a7dbf,#069ef6) ;
    box-shadow: inset 0.1rem 0.1rem 0.3rem #053A5C,
    inset -0.1rem -0.1rem 0.3rem #0ECFFF !important;
    box-sizing: border-box !important;
}

#FinalButton {
    height: 3rem;
}

.RecommendationPrice{
    font-size: 1.5rem;
}

.DediCores {
    opacity: 0.85;
    width: 100%;
    position: relative;
    top: 0;
    background-color: #4fc774;
    color: #1b1e21;
    font-weight: bold;
    border-radius: 0.2rem;
    margin-bottom: -1rem;
}

.DediCores p{
    font-family: Vivala, sans-serif;
    color: inherit;
    font-size: inherit;
    margin: 0;
}

.Region {
    display: flex;
}

.RegionButton {
    color: white;
    font-size: 1.5rem;
    width: 13rem;
    border: none;
    margin: 1rem;
    box-sizing: border-box;
    border-radius: 0.6rem;
    background: #212529;
    box-shadow:  5px 5px 20px #0d0f10, -5px -5px 20px #353b42;
    height: 3rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: Falcon, sans-serif;
}

.RegionClicked,
.RegionButton:hover {
    border: 0.25rem solid #098adb !important;
    background: linear-gradient(#1a7dbf,#069ef6) ;
    box-shadow: inset 0.1rem 0.1rem 0.3rem #053A5C,
    inset -0.1rem -0.1rem 0.3rem #0ECFFF !important;
    box-sizing: border-box !important;

}


.SelectedCard,
.Descriptions:hover {
    box-shadow: 0 0 5px 5px rgb(8 158 240);
}

.OptionsSelector {
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.AllOptions,
.AutoSelector {
    max-width: 100%;
    position: relative;
    overflow: hidden;
    margin: 3rem 1.5rem;
    font-family: Vivala, sans-serif;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 0.7rem;
    box-sizing: border-box;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.50);
    text-decoration: none;
    color: white;
    z-index: 5;
}

.AllOptions {
    width: 70vw;
}

.AutoSelector {
    width: 30vw;
}

.OptionsHeading,
.AutoSelectorHeading {
    font-size: 2rem;
    font-weight: bold;
    height: 3rem;
    padding: 0.6rem 2rem;
    display: flex;
    flex-direction: row;
    background: #1b1e21;
    align-items: center;
    justify-content: space-between;
}

.OptionsHeading p{
    margin: 0 auto;
    width: 40%;
    text-align: center;
}

.OptionsEnding {
    font-family: Vivala, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    height: 3rem;
    padding: 0.6rem 2rem;
    display: flex;
    flex-direction: row;
    background: #1b1e21;
    align-items: center;
    justify-content: space-between;
}
.OptionsEnding a{
    display: flex;
    align-items: center;
    height: 3rem;
    text-decoration: none;
    color: white;
    margin: 0;
    box-sizing: border-box;
    border-radius: 0.5rem;
    padding: 0.3rem 0.5rem;
    background: #11ab59;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.50);
    border: 0.25rem solid #11AB59 !important;

}
.AutoSelectorHeading {
    justify-content: center !important;
}

.Selectors {
    margin: 1rem auto 0;
    width: 90%;
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    font-family: Falcon, sans-serif;
}

.Selectors p{
    margin: 0;
}

.Selectors #BudgetWarning{
    margin: 1rem 0;
    padding: 0.1rem 0;
    font-size: 1rem;
    font-family: Vivala, sans-serif;
    background: #ff5555;
    border-radius: 0.15rem;
    padding-left: 0.5rem;
}



.ConfigSelector {
    display: flex;
    flex-direction: column;

    margin: 1rem 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 3rem;
    padding: 0 1rem 2rem;
    background: rgba(224, 224, 224, 0);
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.50);
}

.ConfigSelector::-webkit-slider-runnable-track{
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    height: 1rem;
    border-radius: 0.3rem;
    box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, 0.50);
}
.ConfigSelector::-webkit-slider-thumb {
    cursor: move;
    -webkit-appearance: none;
    position: relative;
    top: -20%;
    aspect-ratio: 1 / 1;
    width: 1.5rem;
    background-image: linear-gradient(-45deg, rgb(37, 182, 125), rgb(21, 155, 104));
    border-radius: 50%;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.50);
}

.ConfigSelector::-webkit-slider-thumb:active {
    cursor: grabbing;
}

.ConfigMarkers {
    padding: 0;
    width: 94%;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    top: -2.7rem;
}

.ConfigMarkers li {
    width: 1rem;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    font-family: Vivala, sans-serif;
}

.ConfigMarkers li::before {
    content: "\2022";
    position: absolute;
    top: -1rem;
    text-align: center;
    margin: 0 auto;
}

#UltimateMarkers {
    display: none;
}

.AiSelect {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.AiSelect p{
    font-size: 1.3rem;
    margin: 1rem auto;
    text-align: center;
}

.AiSelect label {
    padding: 0.2rem 0.5rem;
    margin: 0.5rem 0;
    width: fit-content;
    font-size: 1.1rem;
    border-radius: 0.3rem;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.3);
}

.AiSelect select{
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    /* Reset */
    appearance: none;
    border: 0;
    outline: 0;

    font: inherit;
    height: 2rem;
    padding: 0 0 0 0.7rem;
    background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg) no-repeat right 0.8em center / 1.4em, linear-gradient(to left, rgba(255, 255, 255, 0.11) 3em, rgba(255, 255, 255, 0.06) 3em);
    color: #dedede;
    border-radius: 0.3rem;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}
.AiSelect select option {
    background-color: rgba(33, 37, 41, 0.9);
}

.AiSelect select option {
    background-color: rgba(33, 37, 41, 0.9);
}

.AiSelect select optgroup {
    background-color: rgb(27, 30, 33);
}
.AiSelect select::-ms-expand {
    display: none;
}

.FeatureHeading{
    color: #c0c0c0
}

.FeatureCards {
    color: #9a9a9a;
    margin: 0 auto;
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
}

.Reason{
    color: #c0c0c0;
    margin: 1rem;
    min-width: 22rem;
    text-align: left;
    justify-content: flex-start;
}

.Reason p{
    color: #9a9a9a;
    padding: 0 1rem;
    font-family: Vivala, sans-serif;
}

.ReasonHeading {
    color: #c0c0c0;
    text-align: left;
    font-family: Falcon, sans-serif;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    background: #1b1e21;
    align-items: center;
}

.ReasonHeading img{
    color: #9a9a9a;
    padding-right: 0.5rem;
    height: 2.5rem;
}

@media (max-width: 1200px) {


    .DediCores {
        top: 0;
    }
    .Plans{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        max-width: none;
    }
    .Descriptions {
        width: 90vw;
        max-width: 20rem;
    }
    .OptionsSelector{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        max-width: none;
    }
    .AllOptions {
        width: 90vw;
    }
    .AutoSelector {
        width: 90vw;
    }
    .Region{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .OptionsHeading, .OptionsEnding{
        padding: 0;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    .OptionsHeading span, .OptionsEnding span {
        display: none;
    }
    #regionDisplay {
        margin-top: 0;
    }

    .UltimateDescription .Features{
        padding-top: 6px;
    }
}