
.Locations {
    display: flex;
    flex-direction: column;
}

.map {
    aspect-ratio : 1 / 0.65;
    width: 50%;
    background-image: url("../assets/map.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
}


.Beauharnois {
    top: 47.3%;
    left: 27.7%;
}

.NewYorkCity {
    top: 50.5%;
    left: 27.5%;
}

.Miami {
    top: 57.5%;
    left: 25.8%;
}

.Germany {
    top: 46%;
    left: 50%;
}



.dot {
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 1%;
    border-radius: 50%;
    background-color: #74ffc2;
    animation: radarPulse 2s infinite;
}

.dot p{
    color: #212529;
    font-family: BrunoAce, sans-serif;
    font-size: 2%;
}

@keyframes radarPulse {
    0% {
        transform: scale(0.2);
        opacity: 1;
    }
    50% {
        transform: scale(2.5);
        opacity: 0.8;
    }
    100% {
        transform: scale(3);
        opacity: 0;
    }
}



.TestPing,
.Result {
    cursor: pointer;
    margin: 3rem 1.5rem;
    vertical-align: middle;
    font-family: BrunoAce, serif;
    text-align: center;
    display: inline-block;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.7rem;
    border: 0.25rem solid transparent;
    box-sizing: border-box;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.50);
    text-decoration: none;
    color: white; /* Text color for the links */
    z-index: 5;
}

.TestPing:hover,
.Pinger{
    cursor: pointer;
    border: 0.25rem solid #098adb;
    background: linear-gradient(#1a7dbf,#069ef6);
    box-shadow: inset 0.1rem 0.1rem 0.3rem #053A5C,
    inset -0.1rem -0.1rem 0.3rem #0ECFFF;
}

.TestPing img,
.Pinger img{
    margin-right: 0.5rem;
    vertical-align: middle;
    width: 3rem;
}


.PingResults{
    display: flex;
    flex-direction: row;
    max-width: 90%;
}

.PingResults p{
    margin: 0 1rem;

    font-family: BrunoAce, sans-serif;
}

.ping-green{
    gap: 10px;
    color: #2ff768;
}
.ping-red{
    gap: 10px;
    color: #ed4245;
}
.ping-yellow{
    gap: 10px;
    color: #dbc95a;
}

.Result h3{
    margin: 0;
    color: azure;
}

@media (max-width: 1200px) {
    .map {
        width: 90%;
    }
    .Locations h1 {
        font-size: 1.5rem;
    }
    .PingResults {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .PingResults p {
        padding: 0 1rem;
        font-size: 1rem;
        margin: 1rem 0;
        display: flex;
        text-align: center;
    }
}