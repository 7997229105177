.helpButton {
    margin: 3rem 1.5rem;
    vertical-align: middle;
    font-family: BrunoAce, serif;
    text-align: center;
    display: inline-flex;
    padding: 0.6rem 0.1rem;
    width: 25%;
    border-radius: 0.7rem;
    box-sizing: border-box;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.50);
    text-decoration: none;
    color: white; /* Text color for the links */
    z-index: 5;
    justify-content: flex-start;
}

.discord ,
.discord:hover{
    border: 0.25rem solid #5172e7;
}
.discord:hover {
    background: linear-gradient(#687ab9, #6984e0);
    box-shadow: inset 0.1rem 0.1rem 0.3rem #485581,
    inset -0.1rem -0.1rem 0.3rem #748fec;
}

.website,
.website:hover {
    border: 0.25rem solid #59DC8D;
}
.website:hover {
    background: linear-gradient(#299f71, #159b68);
    box-shadow: inset 0.1rem 0.1rem 0.3rem #10865b,
    inset -0.1rem -0.1rem 0.3rem #1abf80;
}

.helpButton:hover {
    margin: 3rem 1.5rem;
    vertical-align: middle;
    font-family: BrunoAce, serif;
    text-align: center;
    display: inline-flex;
    padding: 0.6rem 0.1rem;
    width: 25%;
    border-radius: 0.7rem;
    box-sizing: border-box;
}

.helpButton img {
    margin-right: 0.5rem;
    vertical-align: middle;
    height: 10rem;
}

.helpButton h3 {
    font-size: 1.5rem;
    font-family: Bungee,sans-serif;
}


.helpButton h3,
.helpButton p {
    margin: 0;
    text-align: left;
}


.helpDescription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.helpBoxes{
    display: flex;
    justify-content: center;
}

@media (max-width: 1200px) {


    .helpButton img {
        height: 5rem;
    }
    .helpBoxes{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .helpButton{
        width: 90%;
    }

    .helpButton:hover {
        width: 90%;
    }
}