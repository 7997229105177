.Reviews {
    margin-top: 3rem;
    width: 80vw;
    max-width: 1920px;
}
.ReviewsList {
    margin: 0 auto;
    height: auto;
    overflow: hidden;
    position: relative;
    width: 80%;
}



.ReviewsList::before {
    background: linear-gradient(to right, rgb(33, 37, 41) 0%, rgba(255, 255, 255, 0) 75%);
    content: "";
    height: 100%;
    position: absolute;
    width: 15%;
    left: 0;
    top: 0;
    z-index: 2;
}

.ReviewsList::after {
    background: linear-gradient(to right, rgb(33, 37, 41) 0%, rgba(255, 255, 255, 0) 75%);
    content: "";
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.ReviewsTrack {
    animation: scroll 60s linear infinite;
    display: flex;
    margin: 2rem 0;
    width: calc(30rem * 14);
}

.ReviewsTrack:hover{
    animation-play-state: paused;
}

.AReview {
    min-width: 300px;
    padding: 20px;
    align-items: start;
    color: white;
    border-radius: 20px;
    box-shadow: 0 4px 10px 5px rgba(0, 0, 0, 0.50);
    display: inline-flex;
    height: auto;
    width: 30rem;
    margin-top: 15px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.AReview p {
    font-family: Vivala, sans-serif;
}
.stars {
    width: 20%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    border-radius: 1rem;
}

.stars img {
    border-radius: 0.3rem;
}



.ReviewProfile {
    display: flex;
}

.AReview p {
    margin-top: 20px;
}
.ReviewProfile h3{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 1rem;
    text-align: center;
    font-family: BrunoAce, sans-serif;
}
.ReviewProfileImg {
    width: 10%;
    height: auto;
    border-radius: 50px;
}

@keyframes scroll {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
}

.trustpilot-widget a {
    color: white;
    text-decoration: none;
}

@media (max-width: 1200px) {
    .AReview {
        width: 2.5% !important;
    }
    .stars {
        width: 80%;
    }
}