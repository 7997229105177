.Wiki {
    margin-top: 6rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.WikiBoxes {
    width: 80%;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.WikiBoxes p{
    font-family: BrunoAce, serif;
    text-align: center;
    display: block;
    font-size: 1.2rem;
    padding: 0.8rem 0.3rem;
    width: 90%;
    margin: 1rem 0;
    border-radius: 0.7rem;
    border: 0.25rem solid transparent;
    box-sizing: border-box;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.50);
    text-decoration: none;
    color: white;
}

.WikiButtons {
    margin: 0 1rem;
    width: 15%;
}

.WikiButtons p:hover,
.WikiBTNClicked {
    cursor: pointer;
    border: 0.25rem solid #098adb !important;
    background: linear-gradient(#1a7dbf,#069ef6) !important;
    box-shadow: inset 0.1rem 0.1rem 0.3rem #053A5C,
    inset -0.1rem -0.1rem 0.3rem #0ECFFF !important;
}

.WikiContent {
    overflow-y: auto;
    display: inline-block;
    font-family: BrunoAce, serif;
    text-align: left;
    font-size: 1.2rem;
    padding: 1rem 1.2rem;
    height: 30rem;
    width: 50rem;
    border-radius: 0.7rem;
    border: 0.25rem solid transparent;
    box-sizing: border-box;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.50);
    text-decoration: none;
    color: white; /* Text color for the links */
    z-index: 5;

}
.WikiContent::-webkit-scrollbar {
    width: 0.5rem;
}

.WikiContent::-webkit-scrollbar-thumb {
    background: rgba(64, 70, 73, 0.31);
    border-radius: 5rem;
    border: rgba(206, 206, 206, 0.12) 0.1rem solid;
}
.WikiContent::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 246, 255, 0.75);
}

.FAQs {
    display: none;
}

.FAQs a{
    margin: 0.5rem 0;
    color: lightcyan;
    text-decoration: none;
}

.ShowWiki {
    display: flex;
    flex-direction: column;
}

@media (max-width: 1200px) {
    .WikiBoxes {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .WikiButtons {
        width: 30%;
    }
    .WikiButtons p {
        width: 100%;
        font-size: 1rem;
    }
}