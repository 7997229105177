body {
  text-decoration: none;
  margin: 0 auto;
  background: #212529;
}


body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track{
  background: #069ef600;
  border-radius: 100vw;
}
body::-webkit-scrollbar-thumb{
  background: #069ef6;
  border-radius: 100vw;
}



.body {
  margin: 0 auto;
  color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  width: 99vw;
  max-width: 2000px;
  align-items: center;
}

.body h1{
  font-size: 3rem;
  font-family: Bungee,sans-serif;
  margin: 0;
}

.Fader {
  animation: 700ms ease-in animater;
}

@keyframes animater {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@font-face {
  font-family: Bungee;
  src: url("../assets/fonts/Bungee.ttf") format('truetype');
}
@font-face {
  font-family: BrunoAce;
  src: url("../assets/fonts/BrunoAce.ttf") format('truetype');
}
@font-face {
  font-family: Falcon;
  src: url("../assets/fonts/falconpunch.ttf") format('truetype');
}

@font-face {
  font-family: Vivala;
  src: url("../assets/fonts/VivalaSansRound.ttf") format('truetype');
}

@media (max-width: 1200px) {
  .body h1{
    text-align: center;
    margin-top: 3rem;
    font-size: 2.5rem;
  }
}
