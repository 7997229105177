.island {
    display: flex;
    flex-direction: row;
}
.islandimg {
    width: 25vw;
}

.features h1 {
    font-size: 2.25rem;
    margin: 0;
    font-family: Bungee, sans-serif;
}

.features li {
    margin: 0;
    font-family: BrunoAce, sans-serif;
    font-size: 1.5rem;
}

.createServer {
    cursor: pointer;
    margin: 3rem 1.5rem;
    vertical-align: middle;
    font-family: BrunoAce, serif;
    text-align: center;
    display: inline-block;
    font-size: 1.5rem;
    padding: 0.6rem 0.1rem;
    width: 20rem;
    border-radius: 0.7rem;
    border: 0.25rem solid transparent;
    box-sizing: border-box;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.50);
    text-decoration: none;
    color: white;
    z-index: 5;
}

.createServer:hover {
    border: 0.25rem solid #098adb;
    background: linear-gradient(#1a7dbf,#069ef6);
    box-shadow: inset 0.1rem 0.1rem 0.3rem #053A5C,
    inset -0.1rem -0.1rem 0.3rem #0ECFFF;
}

.createServer img {
    margin-right: 0.5rem;
    vertical-align: middle;
    width: 3rem;
}


@media (max-width: 1200px) {

    .islandimg {
        width: 70vw;
    }

    .island {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .features {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .features h1 {
        font-size: 1.5rem;
        text-align: center;
    }
}