.navbar {
    margin: 0 auto;
    width: 95vw;
    max-width: 1800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #212529; /* Background color for the navbar */
    padding: 0 20px; /* Adjust padding as needed */
}


.navbar-logo img {
    cursor: pointer;
    padding-top: 1rem;
    width: 18rem; /* Adjust the logo size */
}

.navbar-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


.navbar-links p, .navbar-links a, #login {
    cursor: pointer;
    font-family: BrunoAce, serif;
    text-align: center;
    display: inline-block;
    font-size: 1.1rem;
    padding: 0.8rem 0.3rem;
    width: 10rem;
    margin-left: 1.5rem;
    border-radius: 0.7rem;
    border: 0.25rem solid transparent;
    box-sizing: border-box;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.50);
    text-decoration: none;
    color: white; /* Text color for the links */
    z-index: 5;
}

.login-options a:hover,
.navbar-links p:hover,
.navbar-links a:hover,
.NavClicked{
    border: 0.25rem solid #098adb !important;
    background: linear-gradient(#1a7dbf,#069ef6) ;
    box-shadow: inset 0.1rem 0.1rem 0.3rem #053A5C,
    inset -0.1rem -0.1rem 0.3rem #0ECFFF !important;
    box-sizing: border-box !important;
}


#login {
    border-radius: 100rem;
    width: 3rem; /* Set the width of the anchor element */
    height: 3rem; /* Set the height of the anchor element */
    background-image: url('../assets/login.png');
    background-size: 150%;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block; /* Display the anchor as a block-level element */
    text-indent: -9999px; /* Hide anchor text, move it off-screen */
    padding: 0.4rem; /* Adjust the padding for a consistent size */
    border: 0.25rem solid transparent; /* Add a transparent border for consistency */
    box-sizing: border-box; /* Include border and padding in element size */
    transition: all 0.3s; /* Add a smooth transition for all properties */
}

#login:hover {
    box-sizing: border-box;
    border-radius: 100rem;
    box-shadow: inset 0.1rem 0.1rem 0.3rem #053A5C,
    inset -0.1rem -0.1rem 0.3rem #0ECFFF;

}

.menu-toggle {
    display: none;
}


/* Add styling for the dropdown menu */
.login-dropdown {
    position: relative;
    display: inline-block;
    z-index: 10;
}

.login-options {
    display: none;
    padding-top: 1rem;
    position: absolute;
    top: 4.2rem;
    left: -5.5rem;
    width: 10rem;
    z-index: 10;
    cursor: pointer;
}

/* Show the dropdown menu when hovering over the login button */
.login-dropdown:hover .login-options {
    display: block;
}

/* Style the login options */
.login-options a {
    border: 0.25rem solid transparent; /* Add a transparent border for consistency */
    margin: 1rem 0;
    color: #fff;
    padding: 10px;
    text-decoration: none;
    display: block;

}


@media (max-width: 1200px) {
    .LogoHamCombo {
        display: flex;
        width: 80vw;
        justify-content: space-between;
    }
    .navbar {
        padding: 0;
        display: grid;
        justify-content: space-around;
        justify-items: center;
        width: 99vw;
    }

    .navbar-links {
        display: none;
        margin: 0;
    }

    .navbar-links.open{
        display: flex;
        flex-direction: column;
        justify-content: center;

    }


    .navbar-links.open a {
        margin: 1rem auto;
        font-family: BrunoAce, serif;
        text-align: center;
        display: inline-block;
        font-size: 1.1rem;
        padding: 0.8rem 0.3rem;
        width: 80vw;
        border-radius: 0.7rem;
        border: 0.25rem solid transparent;
        box-sizing: border-box;
        box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.50);
        text-decoration: none;
        color: white;
    }


    .menu-toggle {

        width: 2rem;
        height: 2rem;
        display: block; /* Show the menu toggle button */
        background: none;
        border: none;
        color: white;
        cursor: pointer;
        padding: 10px;
        margin: 20px 0;
    }

    /* Add a class to change the opacity of the "menu" icon to 0 when the menu is open */
    .menu-toggle.open {
        background-image: url("../assets/close.png");
        background-position: center;
        background-size: 100%;
        opacity: 1;
        transition: all 0.5s ease-in-out;
    }

    /* Add a class to change the opacity of the "close" icon to 0 when the menu is closed */
    .menu-toggle {
        background-image: url("../assets/menu.png");
        background-position: center;
        background-size: 100%;
        opacity: 1;
        transition: all 0.5s ease-in-out;
    }


    .login-dropdown {
        display: contents;
    }


    .login-dropdown:hover .login-options {
        display: contents;
    }

    .navbar-logo img {
        width: 10rem;
    }


}

