.Main {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Transit {
    animation: 800ms ease-in animater;
}

@keyframes animater {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

h1{
    color: aliceblue;
    font-size: 3rem;
    font-family: Bungee,sans-serif;
    margin: 0;
}

.Transit button {
    cursor: pointer;
    margin: 1rem 1.5rem;
    vertical-align: middle;
    font-family: BrunoAce, serif;
    text-align: center;
    display: inline-block;
    font-size: 1.5rem;
    padding: 0.6rem 0.1rem;
    width: 20rem;
    border-radius: 0.7rem;
    border: 0.25rem solid transparent;
    box-sizing: border-box;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.50);
    text-decoration: none;
    background: transparent;
    color: white;
    z-index: 5;
}

.Transit button:hover{
    border: 0.25rem solid #098adb;
    background: linear-gradient(#1a7dbf,#069ef6);
    box-shadow: inset 0.1rem 0.1rem 0.3rem #053A5C,
    inset -0.1rem -0.1rem 0.3rem #0ECFFF;
}

.ExploreAll{

    border: 0.25rem solid #098adb;
    background: linear-gradient(#1a7dbf,#069ef6);
}

.Transit .Back{
    width: 10rem;
    background: linear-gradient(#b63b3b,#cd4242);
    border: 0.25rem solid #d93f3f;
}

.Back:hover{
    border: 0.25rem solid #d93f3f;
    background: linear-gradient(#b63b3b,#cd4242);
    box-shadow: inset 0.1rem 0.1rem 0.3rem #720000,
    inset -0.1rem -0.1rem 0.3rem #da4646;
}
.LocationButton {
    display: flex;
    justify-content: center;
    align-items: center;
}


.Back, .ExploreAll{
    margin: 1rem;
}

@media (max-width: 1200px) {
    .Transit h1{
        text-align: center;
    }
    .Main > div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}