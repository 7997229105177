.EasyPanel{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.SlideShow {
    width: 100%;
    position: relative;
    overflow: hidden;
    justify-content: center;
    
}

.SlideShow::before {
    background: linear-gradient(to right, rgb(33, 37, 41) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100%;
    position: absolute;
    width: 10%;
    left: 0;
    top: 0;
    z-index: 2;
}

.SlideShow::after {
    background: linear-gradient(to right, rgb(33, 37, 41) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100%;
    position: absolute;
    width: 10%;
    z-index: 2;
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}


@keyframes load{
    from{left:-100%;}
    to{left:0;}
}

.slides{
    width:400%;
    height:100%;
    position:relative;
    animation:slide 30s infinite;
}

.slider {
    width: 25%;
    float: left;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.image{
    width:55%;
}

.image img{
    width:100%;
    height:100%;
}

.markers {
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0.8rem;
    z-index: 1;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
}




.marker {
    position: relative;
    background-color: #737373;
    border-radius: 5rem;
    width: 3rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.on {
    position: absolute;
    border-radius: 5rem;
    width: 3rem;
    height: 0.5rem;
    opacity: 1 !important;
    background-color: #00f6ff;
    animation: on 30s infinite;
}



@keyframes on{
    0%,100%{
        margin-left: -10.8rem;
    }
    21%{
        margin-left: -10.8rem;
    }
    25%{
        margin-left: -7.2rem;
    }
    46%{
        margin-left: -7.2rem;
    }
    50%{
        margin-left: -3.6rem;
    }
    71%{
        margin-left: -3.6rem;
    }
    75%{
        margin-left: 0;
    }
    96%{
        margin-left: 0;
    }
}


@keyframes slide{
    0%,100%{
        margin-left:0;
    }
    21%{
        margin-left:0;
    }
    25%{
        margin-left:-100%;
    }
    46%{
        margin-left:-100%;
    }
    50%{
        margin-left:-200%;
    }
    71%{
        margin-left:-200%;
    }
    75%{
        margin-left:-300%;
    }
    96%{
        margin-left:-300%;
    }
}

@media (max-width: 1200px) {
    .SlideShow {
        margin-top: 1rem;
        width: 100%;
    }

    .image{
        width:90%;
    }

    .SlideShow::before {
        background: linear-gradient(to right, rgb(33, 37, 41) 0%, rgba(255, 255, 255, 0) 100%);
        content: "";
        height: 100%;
        position: absolute;
        width: 5%;
        left: 0;
        top: 0;
        z-index: 2;
    }

    .SlideShow::after {
        background: linear-gradient(to right, rgb(33, 37, 41) 0%, rgba(255, 255, 255, 0) 100%);
        content: "";
        height: 100%;
        position: absolute;
        width: 5%;
        z-index: 2;
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

}