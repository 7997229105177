.Footer {
    text-decoration: none;
    font-family: Vivala, sans-serif;
    margin-top: 50px;
    color: white;
    justify-content: center;
    box-shadow: 0 -0.5rem 15px rgba(0, 0, 0, 0.50);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.FooterItems {
    padding-top: 1rem;
    max-width: 1800px;
    display: flex;
    flex-direction: row;
}

.FooterLogo {
    padding: 0 3rem;
    align-self: center;
}
.FooterLogo img {
    width: 75%;
}

.FooterColumn {
    padding: 0 3rem;
    max-width: 25%;
    text-align: center;
}

.FooterHeading {
    font-family: Falcon, sans-serif;
    font-size: 2.5rem;
    padding-bottom: 5%;
}

.Footer p,
.Footer a{
    color: white;
    text-decoration: none;
}

.Footer p:hover,
.Footer a:hover {
    cursor: pointer;
    color: #069ef6;
    text-decoration: underline;
}

.copyright {
    color: #737373;
    text-align: center;
    margin: 0.5rem 0;
    padding: 0;
}


@media (max-width: 1200px) {

    .FooterItems {
        padding-top: 1rem;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
    }

    .FooterLogo img {
        width: 100%;
    }

    .FooterLogo {
        margin-bottom: 1rem;
    }

    .FooterColumn {
        padding: 0 3rem;
        max-width: none;
    }
    
}