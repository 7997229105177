.WhyUs {
    margin-top: 1rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.WhyBoxes {
    display: inline-flex;
    justify-content: space-around;
    width: 85%;
}

.FeatureBox {
    padding: 0.55%;
    width: 30%;
    display: inline-flex;
    border: 0.25rem solid #5fb6d2;
    border-radius: 0.7rem;
}

.FeatureBox img {
    margin-right: 0.5rem;
    height: 10rem;
}

.featuresDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
    font-family: BrunoAce, sans-serif;
}

.featuresDescription h3 {
    line-height: 1.5rem;
    margin: 0;
    font-family: Bungee,sans-serif;
}

.amd:hover,
.support:hover,
.affordable:hover {
    box-shadow:  0 0 0.5rem 0.1rem #acb8c9;
}

@media (max-width: 1200px) {

    .FeatureBox img {
        height: 5rem;
    }
    .WhyBoxes{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .FeatureBox{
        margin: 1rem 0;
        width: 90%;
    }

}